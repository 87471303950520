<template>
    <div class="landing">
        <div class="logo">
            <img src="/partners/sims-logo-new.png" alt="Logo" />
        </div>

        <p>
        Your organisation has partnered with teamSOS to better protect your staff and students. If you don't know your login details, please contact your organisation's administrator.
      </p>

        <!-- Not mobile -->
        <div v-if="!isMobile" class="bottom-wrapper">
            <a class="bottom-button" id="login-button" @click.prevent="login">Click Here To Login</a>
        </div>
        <!-- / Not mobile -->

        <!-- Mobile -->
        <div v-if="isMobile" class="bottom-wrapper">
            <a class="bottom-button" href="https://portal.teamsos.co.uk/download">
                Please tap to download the teamSOS mobile apps
            </a>
        </div>
        <!-- / Mobile -->
    </div>
</template>

<script>
// const _ = require('lodash');
export default {
    name: "Landing",
    data() {
        return {
            isMobile: false,
        };
    },
    created() {
        // device detection
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        ) {
            this.isMobile = true;
        }

        if (this.$route.query.intent) {
            localStorage.setItem('intent', this.$route.query.intent);
            // After half a second, attempt click login button #login-button
            setTimeout(() => {
                document.getElementById('login-button').click();
            }, 50); 
        }

        if (this.$oidc.isAuthenticated) {
            if (window.isLoggingOut) {
                window.isLoggingOut = false;
            } else {
                // If intent, redirect
                if (localStorage.getItem('intent')) {
                    this.$router.push(localStorage.getItem('intent'));
                } else {
                    this.$router.push("/dashboard");
                }
            }
        }
    },
    methods: {
        login() {
            this.$oidc.signIn();
            // this.$router.push("/dashboard");
        },
    },
};
</script>
<style scoped lang="scss">
.landing {
    // background: #5155ea;
    background: #3A0BA3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;

    .logo {
        max-width: 90%;
        width: 90%;
        text-align: center;
    }

    .logo img {
        width: 80%;
        max-width: 220px;
    }

    p {
      color: #fff;
      font-size: 1.2em;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 70px;
      text-align: center;
    }

    .bottom-wrapper {
        margin-top: 70px;
    }

    .bottom-button {
        // margin-top: 70px;
        background: #fff;
        color: #000;
        padding: 20px 80px;
        font-weight: 500;
        font-size: 17px;
        border-radius: 50px;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        transition: background-color 0.2s ease;
        display: block;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
            background: #eee;
        }
    }
}
</style>
